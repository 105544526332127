<template>
  <div class="inside-bg">
   
  </div>
</template>


<script>
import Actions from '../libraries/apiActions.js';

export default ({
  name: "custom_redirect",
  beforeMount(){
    // let url = this.$route.params.custom_redirect;
    this.getRedirects();
  },
  data(){
    return{
      action: new Actions(),
    }
  },
  methods:{
    getRedirects(){
      let url = this.$route.params.custom_link;
      this.action.getActiveRedirects(url).then(res => {
        if(res && res.redirects.length > 0) {
          window.location.href = res.redirects[0].redirect_link;
        } else this.$router.push("/not-found");
      });
    }
  }
})
</script>
